export const fetchAddressMixin = {
  methods: {
    fetchAddress (search, loading) {
      if (search && search.length) {
        loading(true)
        if (this.dadataTimeout) {
          clearTimeout(this.dadataTimeout)
          this.dadataTimeout = undefined
        }
        if (this.$store.state.cancelAxios !== undefined) {
          this.$store.state.cancelAxios()
        }
        this.dadataTimeout = setTimeout(() => {
          this.$store.dispatch('POST_ADDRESS_DADATA', search).then(({ data }) => {
            this.addresses = data.suggestions
            loading(false)
          }).catch(() => {
            loading(false)
          })
        }, 200)
      }
    }
  }
}
