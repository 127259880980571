import moment from 'moment'
import 'moment-weekday-calc'

/**
 * @see https://github.com/d10xa/holidays-calendar
 * @returns {Array} - получает праздники и нерабочие дни с указанного ресурса
 */
async function getHolidays () {
  let holidays = []
  try {
    const response = await fetch('https://raw.githubusercontent.com/d10xa/holidays-calendar/master/json/calendar.json')
    holidays = await response.json()
  } catch (e) {
    console.log(e)
    return holidays
  }

  holidays = [...holidays.holidays, ...holidays.nowork]
  return holidays
}

/**
 * @description - записывает праздники и нерабочие дни в localStorage
 */
export async function setHolidaysToLS () {
  const holidays = await getHolidays()
  localStorage.setItem('holidays', JSON.stringify(holidays))
}

/**
 * @return {Array<string>} - получает из localStorage праздники и нерабочие дни
 */
function getHolidaysFromLS () {
  let holidays = []
  const holidaysJSON = localStorage.getItem('holidays')

  if (holidaysJSON) {
    try {
      holidays = JSON.parse(holidaysJSON)
    } catch (e) {
      console.log(e)
    }
  }
  return holidays
}

/**
 * @return {Date} - последняя дата через 10 рабочих дней с начальной даты
 */
export function getlastDate (dateStart, countDate) {
  const holidays = getHolidaysFromLS()
  return moment(dateStart).isoAddWeekdaysFromSet({
    workdays: countDate,
    weekdays: [1, 2, 3, 4, 5],
    exclusions: holidays
  })
}


