<template>
  <span class="states" aria-hidden="true">
    <img src="/public/img/checkbox.svg" alt="Неактивно">
    <img src="/public/img/checkbox-active.svg" alt="Активно">
  </span>
</template>

<script>
export default {
  name: 'CheckboxStatesIcon'
}
</script>
