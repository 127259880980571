<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z" fill="rgba(37, 87, 137, 0.12)"></path>
    <path d="M12 16L12 12" stroke="#255789" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M12 8L11.99 8" stroke="#255789" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
  </svg>
</template>

<script>
export default {
  name: 'TooltipIcon'
}
</script>
