<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="main main--internal">
    <div class="page-header__container">
      <h1 class="page-header">{{ navigator.title }}</h1>
    </div>
    <form class="steps-form" enctype="multipart/form-data" autocomplete="off" @submit.prevent="submitForm">
      <div class="form-group">
        <div class="form-group__header">
          <span class="form-group__header__title">Ваш судебный приказ о взыскании налогов?</span>
        </div>
        <div class="form-group__body">
          <div class="form-group__body__radio">
            <label class="form-group__radio">
              <input type="radio" v-model="taxes_order" :value="1">
              <RadioStatesIcon/>
              <span class="form-group__radio__title">Да</span>
            </label>
            <label class="form-group__radio">
              <input type="radio" v-model="taxes_order" :value="0">
              <RadioStatesIcon/>
              <span class="form-group__radio__title">Нет</span>
            </label>
          </div>
        </div>
      </div>
      <div class="form-group" ref="custom_judical_title">
        <div class="form-group__header">
          <span class="form-group__header__title">{{ getField("number_judicial_sector_title").title }}</span>
          <div class="form-group__tooltip" v-if="getField('number_judicial_sector_title').description">
            <i
              class="icon"
              aria-describedby="tooltip"
              v-tooltip="{ content: getField('number_judicial_sector_title').description, trigger: $mq === '700' ? 'click' : 'hover' }"
              tabindex="1"
            >
              <TooltipIcon/>
            </i>
          </div>
        </div>
        <div class="form-group__body">
          <div class="_row vertical-middle">
            <div class="_col-12" ref="price">
              <InputField
                :title="getField('number_judicial_sector_id').title"
                :placeholder="getField('number_judicial_sector_id').title"
                v-model="form.number_judicial_sector_id.value"
              >
              <span class="form-group__message__error" v-if="!form.number_judicial_sector_id.value && form.number_judicial_sector_id.message">
                {{ form.number_judicial_sector_id.message }}
              </span>
              </InputField>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="form-group__header">
          <span class="form-group__header__title">{{ getField("debitor_title").title }}</span>
          <div class="form-group__tooltip" v-if="getField('debitor_title').description">
            <i
              class="icon"
              aria-describedby="tooltip"
              v-tooltip="{ content: getField('debitor_title').description, trigger: $mq === '700' ? 'click' : 'hover' }"
              tabindex="1"
            >
              <TooltipIcon/>
            </i>
          </div>
        </div>
        <div class="form-group__body">
          <div class="_row">
            <div class="_col-4" ref="surname_debitor">
              <InputField
                :title="getField('surname_debitor').title"
                :placeholder="getField('surname_debitor').title"
                v-model="form.surname_debitor.value"
              >
                <span class="form-group__message__error" v-if="!form.surname_debitor.value && form.surname_debitor.message">
                  {{ form.surname_debitor.message }}
                </span>
              </InputField>
            </div>
            <div class="_col-4" ref="name_debitor">
              <InputField
                :title="getField('name_debitor').title"
                :placeholder="getField('name_debitor').title"
                v-model="form.name_debitor.value"
              >
                <span class="form-group__message__error" v-if="!form.name_debitor.value && form.name_debitor.message">
                  {{ form.name_debitor.message }}
                </span>
              </InputField>
            </div>
            <div class="_col-4" ref="patronymic_debitor">
              <InputField
                :title="getField('patronymic_debitor').title"
                :placeholder="getField('patronymic_debitor').title"
                v-model="form.patronymic_debitor.value"
              >
                <span class="form-group__message__error" v-if="!form.patronymic_debitor.value && form.patronymic_debitor.message">
                  {{ form.patronymic_debitor.message }}
                </span>
              </InputField>
            </div>
            <div class="_col-4" ref="email">
              <InputField
                :type="'email'"
                :title="getField('email').title"
                :placeholder="getField('email').title"
                v-model="form.email.value"
              >
                <span class="form-group__message__error" v-if="form.email.message">{{ form.email.message }}</span>
              </InputField>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="form-group__header">
          <span class="form-group__header__title">{{ getField("address_debitor_title").title }}</span>
          <div class="form-group__tooltip" v-if="getField('address_debitor_title').description">
            <i
              class="icon"
              aria-describedby="tooltip"
              v-tooltip="{ content: getField('address_debitor_title').description, trigger: $mq === '700' ? 'click' : 'hover' }"
              tabindex="1"
            >
              <TooltipIcon/>
            </i>
          </div>
        </div>
        <div class="form-group__body">
          <div class="_row">
            <div class="_col-12" ref="address_deb">
              <VSelect
                label="value"
                ref="address_debitor"
                v-model="form.address_debitor.value"
                :options="addresses"
                :placeholder="getField('address_debitor').title"
                :searchable="true"
                @search="fetchAddress"
                @search:focus="address"
                :filterable="false"
              >
                <template v-slot:no-options="{ _search, searching, loading }">
                  <template v-if="searching">
                    <template v-if="!loading && !addresses.length">Ничего не найдено</template>
                    <template v-else>Загрузка...</template>
                  </template>
                  <template v-else>Начните вводить адрес</template>
                </template>
              </VSelect>
              <span class="form-group__message__error" v-if="!form.address_debitor.value && form.address_debitor.message">
                {{ form.address_debitor.message }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="form-group__header">
          <span class="form-group__header__title">{{ getField("creditor_title").title }}</span>
          <div class="form-group__tooltip" v-if="getField('creditor_title').description">
            <i
              class="icon"
              aria-describedby="tooltip"
              v-tooltip="{ content: getField('creditor_title').description, trigger: $mq === '700' ? 'click' : 'hover' }"
              tabindex="1"
            >
              <TooltipIcon/>
            </i>
          </div>
        </div>
        <div class="form-group__body">
          <div class="_row">
            <div class="_col-12" ref="creditor_types">
              <VSelect
                label="title"
                ref="creditor_type"
                v-model="form.creditor_type.value"
                :options="creditorTypes"
                :placeholder="getField('creditor_type').title"
                :filterable="false"
              />
              <span class="form-group__message__error" v-if="!form.creditor_type.value && form.creditor_type.message">
                {{ form.creditor_type.message }}
              </span>
            </div>
            <template v-if="form.creditor_type.value && form.creditor_type.value.id">
              <div class="_col-4" v-if="form.creditor_type.value.id === 1" ref="surname_creditor">
                <InputField
                  :title="getField('surname_creditor').title"
                  :placeholder="getField('surname_creditor').title"
                  v-model="form.surname_creditor.value"
                >
                  <span class="form-group__message__error" v-if="!form.surname_creditor.value && form.surname_creditor.message">
                    {{ form.surname_creditor.message }}
                  </span>
                </InputField>
              </div>
              <div class="_col-4" v-if="form.creditor_type.value.id === 1" ref="name_creditor">
                <InputField
                  :title="getField('name_creditor').title"
                  :placeholder="getField('name_creditor').title"
                  v-model="form.name_creditor.value"
                >
                  <span class="form-group__message__error" v-if="!form.name_creditor.value && form.name_creditor.message">
                    {{ form.name_creditor.message }}
                  </span>
                </InputField>
              </div>
              <div class="_col-4" v-if="form.creditor_type.value.id === 1" ref="patronymic_creditor">
                <InputField
                  :title="getField('patronymic_creditor').title"
                  :placeholder="getField('patronymic_creditor').title"
                  v-model="form.patronymic_creditor.value"
                >
                  <span class="form-group__message__error" v-if="!form.patronymic_creditor.value && form.patronymic_creditor.message">
                    {{ form.patronymic_creditor.message }}
                  </span>
                </InputField>
              </div>
              <div class="_col-12" v-if="form.creditor_type.value.id === 2" ref="name_organization_creditor">
                <InputField
                  :title="getField('name_organization_creditor').title"
                  :placeholder="getField('name_organization_creditor').title"
                  v-model="form.name_organization_creditor.value"
                >
                  <span class="form-group__message__error" v-if="!form.name_organization_creditor.value && form.name_organization_creditor.message">
                    {{ form.name_organization_creditor.message }}
                  </span>
                </InputField>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="form-group__header">
          <span class="form-group__header__title">{{ getField("price_title").title }}</span>
          <div class="form-group__tooltip" v-if="getField('price_title').description">
            <i
              class="icon"
              aria-describedby="tooltip"
              v-tooltip="{ content: getField('price_title').description, trigger: $mq === '700' ? 'click' : 'hover' }"
              tabindex="1"
            >
              <TooltipIcon/>
            </i>
          </div>
        </div>
        <div class="form-group__body">
          <div class="_row">
            <div class="_col-12" ref="price">
              <InputField
                :type="'number'"
                :inputmode="'decimal'"
                :step="0.01"
                :title="getField('price').title"
                :placeholder="getField('price').title"
                v-model="form.price.value"
              >
                <span class="form-group__message__error" v-if="!form.price.value && form.price.message">{{ form.price.message }}</span>
              </InputField>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="form-group__header">
          <span class="form-group__header__title">{{ getField("date_takeaway_court_title").title }}</span>
          <div class="form-group__tooltip" v-if="getField('date_takeaway_court_title').description">
            <i
              class="icon"
              aria-describedby="tooltip"
              v-tooltip="{ content: getField('date_takeaway_court_title').description, trigger: $mq === '700' ? 'click' : 'hover' }"
              tabindex="1"
            >
              <TooltipIcon/>
            </i>
          </div>
        </div>
        <div class="form-group__body">
          <div class="_row">
            <div class="_col-12" ref="date_takeaway_court">
              <DatePickerComponent
                v-if="mount"
                v-model="form.date_takeaway_court.value"
                :maxDate="now"
                @input="takeawayDateChange"
              />
              <span class="form-group__message__error" v-if="!form.date_takeaway_court.value && form.date_takeaway_court.message">
                {{ form.date_takeaway_court.message }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group" v-if="!form.date_take_court.disabled">
        <div class="form-group__header">
          <span class="form-group__header__title">{{ getField("date_take_court_title").title }}</span>
          <div class="form-group__tooltip" v-if="getField('date_take_court_title').description">
            <i
              class="icon"
              aria-describedby="tooltip"
              v-tooltip="{ content: getField('date_take_court_title').description, trigger: $mq === '700' ? 'click' : 'hover' }"
              tabindex="1"
            >
              <TooltipIcon/>
            </i>
          </div>
        </div>
        <div class="form-group__body">
          <div class="_row">
            <div class="_col-3" ref="date_take_court">
              <DatePickerComponent
                v-if="mount"
                v-model="form.date_take_court.value"
                :minDate="form.date_takeaway_court.value"
                @input="takeawayDateChange"
                :disabled="form.not_receive_court.value"
                v-bind:class="{disabledInput: form.not_receive_court.value}"
              />
              <span class="form-group__message__error" v-if="!form.date_take_court.value && form.date_take_court.message">
                {{ form.date_take_court.message }}
              </span>
            </div>
            <div class="_col-4">
              <label class="form-group__radio divorce_form-group__radio--similar">
                <input type="checkbox" v-model="form.not_receive_court.value">
                <CheckboxStatesIcon/>
                <span class="form-group__radio__title">{{ getField('not_receive_court').title }}</span>
                <span class="form-group__tooltip" v-if="getField('not_receive_court').description">
                  <i class="icon" aria-describedby="tooltip"
                     v-tooltip="{ content: getField('not_receive_court').description, trigger: $mq === '700' ? 'click' : 'hover' }"
                     tabindex="1">
                    <TooltipIcon/>
                  </i>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group" v-if="this.is10daysBetweenDateTakeawayCourtAndDateToday">
        <div class="form-group__header">
          <span class="form-group__header__title">{{ getField("reason_title").title }}</span>
          <div class="form-group__tooltip" v-if="getField('reason_title').description">
            <i
              class="icon"
              aria-describedby="tooltip"
              v-tooltip="{ content: getField('reason_title').description, trigger: $mq === '700' ? 'click' : 'hover' }"
              tabindex="1"
            >
              <TooltipIcon/>
            </i>
          </div>
        </div>
        <div class="form-group__body">
          <div class="_row">
            <div class="_col-12" ref="reason">
              <InputField
                :title="getField('reason').title"
                :placeholder="getField('reason').title"
                v-model="form.reason.value"
              >
                <span class="form-group__message__error" v-if="!form.reason.value && form.reason.message">{{ form.reason.message }}</span>
              </InputField>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="form-group__header">
          <span class="form-group__header__title">{{ getField("case_number_court_title").title }}</span>
          <div class="form-group__tooltip" v-if="getField('case_number_court_title').description">
            <i
              class="icon"
              aria-describedby="tooltip"
              v-tooltip="{ content: getField('case_number_court_title').description, trigger: $mq === '700' ? 'click' : 'hover' }"
              tabindex="1"
            >
              <TooltipIcon/>
            </i>
          </div>
        </div>
        <div class="form-group__body">
          <div class="_row">
            <div class="_col-12" ref="case_number_court">
              <InputField
                :classes="'order-num'"
                :title="getField('case_number_court').title"
                :placeholder="getField('case_number_court').title"
                v-model="form.case_number_court.value"
              >
                <span class="form-group__message__error" v-if="!form.case_number_court.value && form.case_number_court.message">
                  {{ form.case_number_court.message }}
                </span>
              </InputField>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="form-group__body" ref="turndoc">
          <label class="form-group__radio">
            <input type="checkbox" v-model="form.turndoc.value" value="true">
            <CheckboxStatesIcon/>
            <span class="form-group__radio__title">{{ getField("turndoc").title }}</span>
            <span class="form-group__tooltip" v-if="getField('turndoc').description">
              <i
                class="icon"
                aria-describedby="tooltip"
                v-tooltip="{ content: getField('turndoc').description, trigger: $mq === '700' ? 'click' : 'hover' }"
                tabindex="1"
              >
                <TooltipIcon/>
              </i>
            </span>
          </label>
          <span class="form-group__message__error" v-if="!form.turndoc.value && form.turndoc.message">{{ form.turndoc.message }}</span>
        </div>
      </div>
      <div class="form-group" v-if="form.turndoc.value">
        <div class="form-group__header" ref="total_price">
          <span class="form-group__header__title">{{ getField("total_price").title }}:</span>
          <div class="form-group__tooltip" v-if="getField('total_price').description">
            <i
              class="icon"
              aria-describedby="tooltip"
              v-tooltip="{ content: getField('total_price').description, trigger: $mq === '700' ? 'click' : 'hover' }"
              tabindex="1"
            >
              <TooltipIcon/>
            </i>
          </div>
        </div>
        <div class="form-group__body">
          <div class="_row">
            <div class="_col-12">
              <InputField
                :type="'number'"
                :inputmode="'decimal'"
                :step="0.01"
                :classes="'order-num'"
                :title="getField('total_price').title"
                :placeholder="getField('total_price').title"
                v-model="form.total_price.value"
              >
              </InputField>
              <span class="form-group__message__error"  v-if="!form.total_price.value && form.total_price.message">
                 Поле сумма списанных денег обязательно для заполнения
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="form-group__body" ref="agree">
          <ValidationProvider
            name=" "
            rules="required"
            v-slot="{ errors }"
            style="width: 100%"
          >
            <label class="form-group__radio">
              <input type="checkbox" v-model="form.agree.value" required :true-value="1" :false-value="null">
              <CheckboxStatesIcon/>
              <span class="form-group__radio__title">Согласен на обработку и хранение персональных данных и
                <router-link :to="{ name: 'confidentiality' }" target="_blank">
                  Политикой конфиденциальности
                </router-link>
                <br/>
                <router-link :to="{ name: 'agreement' }" target="_blank">Пользовательское соглашение</router-link>
              </span>
            </label>
            <div class="form-group__message__error" v-if="!form.agree.value && errors[0]">Примите условия соглашения
            </div>
            <div class="form-group__message__error" v-else-if="form.agree.value === null && form.agree.message">
              {{ stepForm.agree.message }}
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="steps-form__footer">
        <div class="steps-form__buttons">
          <button type="submit" class="steps-next button button--fill">
            Получить готовый документ: {{ bot.price }} ₽
          </button>
        </div>
        <div class="form-group__header__title">
          Мы гарантируем конфиденциальность внесенных данных, а также возврат денег, если суд не примет наш иск.
          Есть вопросы и предложения? Мы с удовольствием их примем по тел. <a href="tel:+79882727273">+7 988 272 72 73</a> или support@sudbot.ru.
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import CheckboxStatesIcon from 'components/svg/CheckboxStates.vue'
import TooltipIcon from 'components/svg/Tooltip.vue'
import InputField from 'components/InputField.vue'
import RadioStatesIcon from 'components/svg/RadioStates.vue'
import moment from 'moment'
import petrovich from 'petrovich'
import { setHolidaysToLS, getlastDate } from '@/plugins/utils.js'
import { fetchAddressMixin } from '@/mixins/fetchAddressMixin.js'
import { scrollToErrorsMixin } from '@/mixins/scrollToErrorsMixin.js'

export default {
  name: 'CancelBotPage',
  mixins: [fetchAddressMixin, scrollToErrorsMixin],
  components: {
    DatePickerComponent: () => import('@/components/DatePickerComponent.vue'),
    // компонент должен грузиться асинхронно т.к. v-calendar писали дауны + он должен отображаться после mounted (data -> mount === true)
    TooltipIcon,
    CheckboxStatesIcon,
    InputField,
    RadioStatesIcon
  },
  async asyncData ({ store }) {
    await store.dispatch('GET_CANCEL_BOT_PAGE')
  },
  data () {
    return {
      mount: false,
      creditorTypes: [{
        title: 'Физ. лицо',
        id: 1
      }, {
        title: 'Юр. лицо или налоговая',
        id: 2
      }],
      now: moment().format(),
      addresses: [],
      companies: [],
      taxes_order: 0,
      dadataTimeout: undefined,
      judicalSectors: [],
      form: {
        creditor_type: {
          value: null,
          message: null
        },
        number_judicial_sector_id: {
          value: null,
          message: null
        },
        surname_debitor: {
          value: '',
          message: null
        },
        name_debitor: {
          value: '',
          message: null
        },
        patronymic_debitor: {
          value: '',
          message: null
        },
        surname_debitor_rod: {
          value: null,
          message: null
        },
        name_debitor_rod: {
          value: null,
          message: null
        },
        patronymic_debitor_rod: {
          value: null,
          message: null
        },
        sex_debitor: {
          value: null,
          message: null
        },
        email: {
          value: null,
          message: null
        },
        address_debitor: {
          value: null,
          message: null
        },
        surname_creditor: {
          value: '',
          message: null
        },
        name_creditor: {
          value: '',
          message: null
        },
        patronymic_creditor: {
          value: '',
          message: null
        },
        surname_creditor_rod: {
          value: null,
          message: null
        },
        name_creditor_rod: {
          value: null,
          message: null
        },
        patronymic_creditor_rod: {
          value: null,
          message: null
        },
        sex_creditor: {
          value: null,
          message: null
        },
        name_organization_creditor: {
          value: null,
          message: null
        },
        price: {
          value: null,
          message: null
        },
        date_takeaway_court: {
          value: null,
          message: null
        },
        not_receive_court: {
          value: null,
          message: null
        },
        date_take_court: {
          value: null,
          message: null,
          disabled: true
        },
        reason: {
          value: null,
          message: null
        },
        case_number_court: {
          value: null,
          message: null
        },
        turndoc: {
          value: null,
          message: null
        },
        total_price: {
          value: null,
          message: null
        },
        agree: {
          value: null,
          message: null
        },
        reason_required: {
          value: null,
          message: null
        }
      }
    }
  },
  mounted () {
    this.mount = true
    if (localStorage.stepFormCancel) {
      Object.assign(this.form, JSON.parse(localStorage.stepFormCancel))
    }
    setHolidaysToLS()
  },
  watch: {
    form: {
      handler: function () {
        if (!this.form.turndoc.value) this.form.total_price.value = null
        if (this.form.creditor_type.value && this.form.creditor_type.value.id === 2) {
          this.form.surname_creditor.value = ''
          this.form.name_creditor.value = ''
          this.form.patronymic_creditor.value = ''
        } else if (this.form.creditor_type.value && this.form.creditor_type.value.id === 1) {
          this.companies = []
          this.form.name_organization_creditor.value = null
        }
        localStorage.stepFormCancel = JSON.stringify((this.form))
      },
      deep: true
    }
  },
  computed: {
    navigator () {
      return this.$store.state.cancel_bot_page.navigator
    },
    bot () {
      return this.$store.state.cancel_bot_page.bot
    },
    /**
     * @return {boolean} - прошло ли 10 рабочих дней от даты вынесения судебного приказа до
     * даты получения судебного приказа (не считая дату вынесения)
     */
    is10daysBetweenDateTakeawayCourtAndDateToday () {
      if (!this.form.date_takeaway_court.value) return false
      const TODAY = new Date()
      const countDate = this.taxes_order ? 20 : 10
      const lastDate = new Date(getlastDate(this.form.date_takeaway_court.value, countDate))
      return TODAY > lastDate
    }
  },
  methods: {
    takeawayDateChange () {
      if (this.form.date_take_court.disabled) {
        this.form.date_take_court.value = null
        this.form.date_take_court.disabled = false
      }

      this.form.reason_required.value = this.is10daysBetweenDateTakeawayCourtAndDateToday
    },
    address () {
      if (this.form.address_debitor.value) {
        this.$refs.address_debitor.search = this.form.address_debitor.value.value
      }
    },
    setGenitive () {
      const genitive = petrovich({
        last: this.form.surname_debitor.value,
        first: this.form.name_debitor.value,
        middle: this.form.patronymic_debitor.value
      }, 'genitive')
      this.form.surname_debitor_rod.value = genitive.last
      this.form.name_debitor_rod.value = genitive.first
      this.form.patronymic_debitor_rod.value = genitive.middle
      this.form.sex_debitor.value = genitive.gender
      if (this.form.creditor_type.value && this.form.creditor_type.value.id === 1) {
        const genitiveCreditor = petrovich({
          last: this.form.surname_creditor.value,
          first: this.form.name_creditor.value,
          middle: this.form.patronymic_creditor.value
        }, 'genitive')
        this.form.surname_creditor_rod.value = genitiveCreditor.last
        this.form.name_creditor_rod.value = genitiveCreditor.first
        this.form.patronymic_creditor_rod.value = genitiveCreditor.middle
        this.form.sex_creditor.value = genitiveCreditor.gender
      }
    },
    /**
     * Сброс сообщений от валидации laravel
     */
    resetValidation () {
      Object.keys(this.form).forEach(field => {
        this.form[field].message = null
        if (this.form[field].value && typeof this.form[field].value.forEach === 'function') {
          this.form[field].value.forEach(fld => {
            Object.keys(fld).forEach(key => {
              if (fld[key] && fld[key].message) fld[key].message = null
            })
          })
        }
      })
    },
    submitForm () {
      this.resetValidation()
      this.setGenitive()
      const data = new FormData()
      Object.keys(this.form).forEach(field => {
        if (this.form[field].value !== null) {
          if (field === 'address_debitor') {
            if (this.form[field].value !== null) data.append(field, this.form[field].value.unrestricted_value)
          } else if (typeof this.form[field].value === 'object') {
            if (this.form[field].value !== null) data.append(field, this.form[field].value.id)
          } else if (typeof this.form[field].value === 'boolean') {
            if (this.form[field].value !== null) data.append(field, this.form[field].value ? '1' : '0')
          } else {
            if (this.form[field].value !== null) data.append(field, this.form[field].value)
          }
        }
      })
      this.$store.dispatch('POST_CANCEL_BOT', data).then(res => {
        // if (res.data.link) {
        //   const link = document.createElement('a')
        //   link.setAttribute('href', this.$store.state.api + res.data.link)
        //   link.setAttribute('download', this.$store.state.api + res.data.link)
        //   link.click()
        // }
        if (res.data.url) {
          location.href = res.data.url
        }
      }).catch(({ response }) => {
        let errInput = Object.keys(response.data)[0]
        switch (errInput) {
          case 'number_judicial_sector_id':
            errInput = 'number_judicial_sector'
            break
          case 'address_debitor':
            errInput = 'address_deb'
            break
          case 'creditor_type':
            errInput = 'creditor_types'
            break
          default:
            break
        }
        this.scrollToErrors(errInput)
        this.$swal('Пожалуйста, проверьте правильность заполненных полей!')
        // Вывод ошибок валидаций от Laravel
        Object.keys(response.data).forEach(message => {
          Object.keys(this.form).forEach(field => {
            if (field === message && this.form[message]) {
              this.form[field].message = response.data[message][0]
            } else {
              const regex = /(.+)(\.\d\.)(.+)/
              if (message.match(regex)) {
                if (message.match(regex)[3]) {
                  const arrayName = message.match(regex)[1]
                  const arrayIndex = message.match(regex)[2]
                  const arrayField = message.match(regex)[3]
                  if (field === arrayName && this.form[field]) {
                    this.form[field].value[arrayIndex.replace(/\./g, '')][arrayField].message = response.data[message][0]
                  }
                }
              }
            }
          })
        })
      })
    },
    getField (name) {
      return this.$store.state.cancel_bot_page.values_bot.find(item => {
        return item.name === name
      })
    }
  },
  metaInfo () {
    const navigator = this.$store.state.cancel_bot_page.navigator
    return this.$seo(
      'common',
      navigator.title,
      navigator.meta_keywords,
      navigator.description,
      navigator.title,
      null,
      navigator.description
    )
  }
}
</script>
