
import scrollIntoView from 'smooth-scroll-into-view-if-needed'

export const scrollToErrorsMixin = {
  methods: {
    scrollToErrors (errInput) {
      if (
        errInput === 'spouse_address' ||
        errInput === 'address' ||
        errInput === 'mailing_address'
      ) errInput = errInput + 's'
      scrollIntoView(this.$refs[errInput], {
        behavior: 'smooth',
        block: 'start'
      })
    },
    scrollToCountedErrors (errInput, index) {
      scrollIntoView(this.$refs[errInput][index], {
        behavior: 'smooth',
        block: 'start'
      })
    }
  }
}
